import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import periodType from '@/constants/period-type'
import payoutStatus from '@/constants/payout-status'
import ability from '@/plugins/acl/ability'

export default function usePaymentList() {
  const payoutsTableColumns = [
    {
      text: 'UID', value: 'payoutCode', sortable: false, width: 220,
    },
    {
      text: 'CREATED ON', value: 'createdAt', align: 'center', width: 150,
    },
    {
      text: 'SHOP', value: 'shopName', sortable: false, width: 200,
    },
    {
      text: 'AMOUNT', value: 'amount', sortable: false, align: 'end', width: 150,
    },
    {
      text: 'SENT AMOUNT', value: 'sentAmount', sortable: false, align: 'end', width: 150,
    },
    {
      text: 'METHOD', value: 'methodName', sortable: false, width: 150,
    },
    {
      text: 'STATUS', value: 'payoutStatus', sortable: false, align: 'center', width: 180,
    },
    {
      text: 'REFERENCE CODE', value: 'referenceCode', sortable: false, width: 220,
    },
    {
      text: 'BENEFICIARY', value: 'beneficiary', sortable: false, width: 100,
    },
    {
      text: 'DESCRIPTION', value: 'description', sortable: false, width: 200,
    },
  ]

  const canCreate = ability.can('create', 'payouts')

  const showDateRange = ref(false)

  const payoutItems = ref([])
  const payoutItemsLength = ref(0)

  const searchFilterModel = ref('')

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])

  const statusFilterModel = ref(null)
  const statusFilterItems = ref([])

  const dateFilterModel = ref([
    new Date().toISOString().substring(0, 10),
    new Date().toISOString().substring(0, 10),
  ])

  const periodTypeItems = ref([])
  const periodTypeModel = ref(null)

  const dateRangeText = computed(() => dateFilterModel.value.join(' ~ '))
  const isAddNewPayoutSidebarActive = ref(false)

  const loading = ref(false)

  const options = ref({
    sortBy: ['p.createdAt'],
    sortDesc: [true],
  })

  const snackbar = ref({
    isSnackbarVisible: false,
    snackBarColor: 'info',
    snackbarMessage: '',
    snackbarTimeOut: '3000',
  })

  const showSnackbarMessage = data => {
    let msg = data.message
    if (data.type === 'error') {
      msg = '<p class="red--text">ERROR:</p>'
      data.message.forEach(x => {
        msg += `<li>${x.message}</li>`
      })
    }
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = data.type
    snackbar.value.snackbarMessage = msg
  }

  const menuDateRange = ref(false)

  const fetchList = () => {
    loading.value = true
    store
      .dispatch('processing-payouts/fetchList', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        shopId: shopFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const { records, count } = response.data
        payoutItems.value = records
        payoutItemsLength.value = count
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const onSearch = () => {
    if (searchFilterModel.value === '') return
    fetchList()
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('processing-payouts/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        statusFilterItems.value = config.payoutStatus
        periodTypeItems.value = config.periodTypes
        periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const downloadList = () => {
    loading.value = true
    store
      .dispatch('processing-payouts/export', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        shopId: shopFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = response.headers.filename
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => error).finally(() => {
        loading.value = false
      })
  }

  const onPayoutSaved = message => {
    showSnackbarMessage({ type: 'info', message: `Your payout: <strong>${message}</strong> has been created successfully!` })
  }

  const onPayoutError = message => {
    showSnackbarMessage({ type: 'error', message })
  }

  const onPeriodTypeChange = () => {
    showDateRange.value = (periodTypeModel.value === periodType.CUSTOM)
  }

  const onUpdateSideBarActive = () => {
    
  }

  watch([options], () => {
    fetchList()
  })

  const applyFilters = () => {
    options.value.page = 1
    searchFilterModel.value = null
    fetchList()
  }

  const resetFilters = () => {
    searchFilterModel.value = ''
    shopFilterModel.value = null
    statusFilterModel.value = null
    periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
    showDateRange.value = false
    dateFilterModel.value = [new Date().toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)]
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    switch (status) {
      case payoutStatus.PENDING:
        return 'payout-status--pending'
      case payoutStatus.PAID:
        return 'payout-status--paid'
      case payoutStatus.PROCESSING:
        return 'payout-status--processing'
      case payoutStatus.REJECTED:
        return 'payout-status--rejected'
      default:
        return 'payout-status--pending'
    }
  }

  return {
    payoutItems,
    payoutItemsLength,
    payoutsTableColumns,
    searchFilterModel,
    shopFilterItems,
    shopFilterModel,
    statusFilterModel,
    statusFilterItems,
    periodTypeModel,
    periodTypeItems,
    dateFilterModel,
    menuDateRange,
    loading,
    options,
    showDateRange,
    snackbar,
    dateRangeText,
    isAddNewPayoutSidebarActive,
    canCreate,
    showSnackbarMessage,
    onPayoutSaved,
    onPayoutError,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    onSearch,
    downloadList,
    resolveStatusVariant,
    onPeriodTypeChange,
    onUpdateSideBarActive,
  }
}
